import { FunctionComponent } from 'react';
import Async, { PromiseFn } from 'react-async';
import ContentLoader from 'react-content-loader';
import { IconError, Title } from '@platformeco/ui-components';
import cn from 'classnames';
import prettyBytes from 'pretty-bytes';
import { getProcessingModuleStats, EnvironmentInfo, ProcessingModuleStats } from 'api';
import StatusBadge from 'components/StatusBadge';
import styles from './DeployStats.module.css';

const getStats: PromiseFn<ProcessingModuleStats> = async ({
  name,
  controlCenterURL,
  env,
  from,
  to,
}) => {
  return await getProcessingModuleStats(name, env, from, to, controlCenterURL);
};

const DeployStats: FunctionComponent<Props> = ({
  env,
  name,
  vertical,
  controlCenterURL,
}: Props) => {
  const from = new Date();
  from.setHours(0);
  from.setMinutes(0);
  from.setSeconds(0);
  from.setMilliseconds(0);
  const to = new Date();

  const onReject = (err: Error) =>
    console.error(`Error occured during "${name}" deploy stats loading`, err);

  return (
    <Async
      promiseFn={getStats}
      watch={env}
      env={env}
      name={name}
      controlCenterURL={controlCenterURL}
      from={from}
      to={to}
      onReject={onReject}
    >
      <Async.Pending>
        <DeployStatsLoading vertical={vertical} />
      </Async.Pending>
      <Async.Rejected>
        <DeployStatsError vertical={vertical} />
      </Async.Rejected>
      <Async.Fulfilled>
        {(stats: ProcessingModuleStats) => (
          <section className={cn(styles.container, { [styles.vertical]: vertical })}>
            <div className={styles.block}>
              <span>In/Out traffic</span>
              <span>
                {prettyBytes(stats.traffic.incoming)}/{prettyBytes(stats.traffic.outgoing)}
              </span>
            </div>
            <div className={styles.block}>
              <span>Restarts</span>
              <span>{stats.restartsCount}</span>
            </div>
            <div className={styles.block}>
              <span>Last deploy</span>
              <span>{printLastDeploy(stats.lastDeploy)}</span>
            </div>
            <div className={styles.block}>
              <span>Version</span>
              <span>{stats.channelVersion}</span>
            </div>
            <div className={styles.pad} />
            <StatusBadge value={stats.status} className={styles.status} />
          </section>
        )}
      </Async.Fulfilled>
    </Async>
  );
};

function DeployStatsError({ vertical }: { vertical?: boolean }) {
  return (
    <div className={cn(styles.error, { [styles.vertical]: vertical })}>
      <IconError size="XL" />
      <Title level={4}>Couldn't load deployment statistics</Title>
    </div>
  );
}

export function DeployStatsLoading({ vertical }: { vertical?: boolean }) {
  if (vertical)
    return (
      <section className={styles.vertical}>
        <ContentLoader
          width={560}
          height={68}
          backgroundColor="#ebebeb55"
          foregroundColor="#f0f0f022"
        >
          <rect x="0" y="20" rx="7" ry="7" width="114" height="38" />
          <rect x="154" y="20" rx="7" ry="7" width="69" height="38" />
          <rect x="263" y="20" rx="7" ry="7" width="145" height="38" />
          <rect x="448" y="20" rx="7" ry="7" width="64" height="38" />
        </ContentLoader>
      </section>
    );
  return (
    <section>
      <ContentLoader
        width={848}
        height={68}
        style={{ width: '100%' }}
        backgroundColor="#ebebeb55"
        foregroundColor="#f0f0f022"
      >
        <rect x="3" y="33" rx="7" ry="7" width="231" height="18" />
        <rect x="258" y="33" rx="7" ry="7" width="79" height="18" />
        <rect x="361" y="33" rx="7" ry="7" width="239" height="18" />
        <rect x="624" y="33" rx="7" ry="7" width="100" height="18" />
      </ContentLoader>
    </section>
  );
}

function printLastDeploy(d?: Date) {
  if (!d) return 'Unknown';
  const padNum = (n: number): string => n.toString().padStart(2, '0');
  return `${d.toLocaleDateString()} at ${padNum(d.getHours())}:${padNum(d.getMinutes())}`;
}

interface Props {
  name: string;
  controlCenterURL?: string;
  env: EnvironmentInfo;
  vertical?: boolean;
}

export default DeployStats;
