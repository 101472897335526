import { WretcherResponse } from 'wretch';
import { throttlingCache } from 'wretch-middlewares';
import { getConfig } from './config';
import { authRedirect, client } from './helpers';

const currentUserCache = throttlingCache({ throttle: 60e3 });
const userAppsCache = throttlingCache({ throttle: 3600e3 });

export async function getCurrentUser(): Promise<User> {
  const config = await getConfig();
  const { data } = await client
    .middlewares([currentUserCache])
    .url(`${config.auth}/api/v1/users/me`)
    .resolve((res) => res.json())
    .get();

  return data;
}

export async function getUserOrganizations(): Promise<Organization[]> {
  const config = await getConfig();
  const { data } = await client
    .url(`${config.auth}/api/v1/organizations/mine`)
    .resolve((res) => res.json())
    .get();

  return data;
}

export async function getApplications(orgId: string): Promise<Application[]> {
  const config = await getConfig();
  const { data } = await client
    .url(`${config.auth}/api/v1/organizations/${orgId}/applications/mine`)
    .middlewares([userAppsCache])
    .resolve((res) => res.json())
    .get();

  return data;
}

export const getUserApplications = async (): Promise<Application[]> => {
  const orgs = await getUserOrganizations();

  if (!orgs.length) {
    return [] as Application[];
  }

  const apps = await Promise.all(orgs.map((org: Organization) => getApplications(org.id)));
  return apps.flat();
};

export enum RepoTypes {
  Github = 'github',
  Gitlab = 'gitlab',
  Bitbucket = 'bitbucket',
}

export type Repository = {
  repoType: `${RepoTypes}`;
  repoName: string;
  defaultBranch: string;
  baseURL: string;
};

export enum HandleTypes {
  ControlCenter = 'controlCenter',
  Devportal = 'devportal',
}

const getControlCenterHandle = (handles: Handle[]) => {
  if (!handles) {
    return;
  }

  const [ccHandle] = handles.filter(({ type }) => type === HandleTypes.ControlCenter);
  return ccHandle;
};

const getRepositoryUrl = (repositories: Repository[]) => {
  const [repository] = repositories;
  if (!repository) {
    return '';
  }

  const { baseURL, repoName } = repository;
  return `${baseURL}/${repoName}.git`;
};

export const getUserApps = async () => {
  const apps = await getUserApplications();

  if (!apps) return [];
  const result = await Promise.all(
    apps.map(async (app) => {
      const controlCenterURL = getControlCenterHandle(app.handles);
      const repositoryURL = getRepositoryUrl(app.repositories);
      return {
        ...app,
        ...(controlCenterURL && { controlCenterURL: controlCenterURL.value }),
        ...(repositoryURL && { repositoryURL }),
      };
    }),
  );
  console.log(result);
  return result;
};

export async function getUserSettings(id: string): Promise<User> {
  const config = await getConfig();
  const { data } = await client
    .url(`${config.auth}/api/v1/users/${id}`)
    .resolve((res) => res.json())
    .get();

  return data;
}

export async function setUserSettings(id: string, data: Settings): Promise<WretcherResponse> {
  const config = await getConfig();
  return await client.url(`${config.auth}/api/v1/users/${id}/name`).put(data).res();
}

export const updateToken = async (userId: string, data: UserToken[]): Promise<WretcherResponse> => {
  const config = await getConfig();

  return await client.url(`${config.auth}/api/v1/users/${userId}/tokens`).put(data).res();
};

export const logout = async () => {
  const config = await getConfig();
  await client.url(`${config.auth}/api/v1/auth/signout`).post().res();
  authRedirect(window.location.href);
};

export type Settings = { name: string };

type Email = {
  address: string;
  verified: boolean;
};

export interface User {
  id: string;
  emails: Email[];
  name: string;
  primaryEmail: string;
  avatarSizes: number[];
  tokens: UserToken[];
  region: string;
  language: string;
  createdAt: string;
  updatedAt: string;
}

export interface Organization {
  id: string;
  title: string;
}

export type Handle = {
  type: HandleTypes.ControlCenter | HandleTypes.Devportal;
  value: string;
};

export interface Application extends Organization {
  orgID: string;
  repositories: Repository[];
  handles: Handle[];
}

export interface UserToken {
  title: string;
  value: string;
  domain: string;
  due?: string;
}

export type UpdateTokenPayload = Partial<UserToken>;

export interface ApplicationInfo extends Application {
  controlCenterURL?: string;
  repositoryURL?: string;
}
