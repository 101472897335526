import { FunctionComponent } from 'react';
import { Title, Button } from '@platformeco/ui-components';
import cn from 'classnames';
import styles from './BigError.module.css';
import { ReactComponent as ErrorImage } from './error.svg';

const BigError: FunctionComponent<Props> = ({ className, children, action, onAction }) => (
  <div className={cn(styles.container, className)}>
    <ErrorImage className={styles.errorImage} />
    {children && <Title level={2}>{children}</Title>}
    {action && (
      <Button
        className={styles.action}
        size="M"
        variant="ghost"
        onClick={() => onAction && onAction()}
      >
        {action}
      </Button>
    )}
  </div>
);

export default BigError;

interface Props {
  className?: string;
  children?: string;
  action?: string;
  onAction?: () => void;
}
