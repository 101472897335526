import React, { useState } from 'react';
import { useAsync } from 'react-async';
import {
  Plate,
  Title,
  InputText,
  Button,
  notifier,
  IconClose,
  IconEdit,
  Modal,
} from '@platformeco/ui-components';
import cn from 'classnames';
import { useAuth } from 'components/Auth';
import { updateToken, UpdateTokenPayload, UserToken } from '../../api/auth';
import styles from './GitTokens.module.css';

const toDateString = (input: string) => new Date(input).toDateString();
const toISOString = (input: string) => new Date(input).toISOString();
const formatDate = (input: string) => toISOString(input).split('T')[0];

const EditTokenModal: React.FC<{
  token: UserToken;
  index: number;
  isActive: boolean;
  close: () => void;
}> = ({ isActive, index, close, token: { title, domain, due, value } }) => {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const dueFormatted = due ? formatDate(due) : undefined;

  const [selectedToken, setSelectedToken] = useState<UserToken>({
    title,
    domain,
    value,
    due: dueFormatted,
  });
  const [payload, setPayload] = useState<UserToken>({
    title,
    domain,
    value,
    due: dueFormatted,
  });

  const updatePayload = <T extends keyof UserToken>(key: T, value: UpdateTokenPayload[T]) =>
    setPayload({
      ...payload,
      [key]: value,
    });

  const { run: update } = useAsync<void>({
    deferFn: async ([token]) => {
      if (user) {
        const { tokens, id } = user;
        const selected = tokens[index];
        const dueFormatted = selected.due ? formatDate(selected.due) : undefined;
        setSelectedToken({ ...selected, due: dueFormatted });

        const updated = {
          ...token,
          due: toISOString(token.due),
        };

        tokens[index] = updated;
        setLoading(true);
        await updateToken(id, tokens);
        setUser({ ...user, tokens });
      }
    },
    onResolve() {
      setLoading(false);
      notifier.success({ message: 'Success', description: 'Token updated' });
      close();
    },
    onReject(err) {
      setLoading(false);
      console.error('Error occured updating token', err);
      if (user) {
        const { tokens } = user;
        setPayload(selectedToken);
        tokens[index] = selectedToken;
      }
      notifier.error({ message: 'Error', description: 'Failed to update token' });
      close();
    },
  });

  if (!isActive || !user) return <></>;

  return (
    <Modal overlayClassName={styles.modalOverlay} onClose={close} className={styles.modal}>
      <div className={styles.addTokenForm}>
        <Title className={styles.tokensTitle} level={2}>
          Edit Token
        </Title>
        <InputText
          className={styles.addTokenInput}
          size="L"
          label="Label"
          placeholder="Full-Access-Token"
          value={payload.title}
          onChange={({ currentTarget: { value } }) => updatePayload('title', value)}
        />

        <InputText
          className={styles.addTokenInput}
          size="L"
          label="Host"
          placeholder="github.com"
          value={payload.domain}
          onChange={({ currentTarget: { value } }) => updatePayload('domain', value)}
        />

        <InputText
          className={styles.addTokenInput}
          size="L"
          label="Expires At"
          type="date"
          value={payload.due}
          onChange={({ currentTarget: { value } }) => updatePayload('due', value)}
        />

        <InputText
          className={styles.addTokenInput}
          size="L"
          label="New Token"
          value={payload.value}
          type="password"
          placeholder="Will rewrite your old token value"
          onChange={({ currentTarget: { value } }) => updatePayload('value', value)}
        />
        <div className={styles.addTokenButtons}>
          <Button className={styles.addTokenButton} size="L" variant="ghost" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            className={styles.addTokenButton}
            size="L"
            variant="access"
            onClick={() => update(payload)}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DeleteTokenModal: React.FC<{
  index: number;
  isActive: boolean;
  close: () => void;
}> = ({ isActive, close, index }) => {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const { run: remove } = useAsync({
    deferFn: async () => {
      if (user) {
        const { tokens, id } = user;

        tokens.splice(index, 1);
        setLoading(true);
        await updateToken(id, tokens);
        setUser({ ...user, tokens });
      }
    },
    onResolve() {
      setLoading(false);
      notifier.success({ message: 'Success', description: 'Token deleted' });
      close();
    },
    onReject(err) {
      setLoading(false);
      console.error('Error occured updating token', err);
      notifier.error({ message: 'Error', description: 'Failed to delete token' });
      close();
    },
  });

  if (!isActive) return <></>;

  return (
    <Modal overlayClassName={styles.modalOverlay} onClose={close} className={styles.modalDelete}>
      <div className={styles.addTokenForm}>
        <Title className={styles.tokensTitle} level={2}>
          Are you sure?
        </Title>
        <div className={styles.addTokenButtons}>
          <Button className={styles.addTokenButton} size="L" variant="ghost" onClick={close}>
            Cancel
          </Button>
          <Button
            className={styles.addTokenButton}
            size="L"
            variant="deny"
            disabled={loading}
            onClick={remove}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const TokenListItem: React.FC<{
  token: UserToken;
  index: number;
}> = ({ token, index }) => {
  const [isEditModalActive, setEditModalActive] = useState(false);
  const [isDeleteModalActive, setDeleteModalActive] = useState(false);

  return (
    <div className={styles.tokenListItem} style={{ width: '100%' }}>
      <EditTokenModal
        isActive={isEditModalActive}
        token={token}
        index={index}
        close={() => setEditModalActive(false)}
      />
      <DeleteTokenModal
        isActive={isDeleteModalActive}
        index={index}
        close={() => setDeleteModalActive(false)}
      />
      <div>
        <span className={styles.tokenListItemLabel}>{token.title}</span>
        <span className={styles.tokenListItemContent}>host: {token.domain}</span>
        {token.due && (
          <>
            <span className={styles.tokenListItemContent}>|</span>
            <span className={styles.tokenListItemContent}>
              Expires At: {token.due ? toDateString(token.due) : ''}
            </span>
          </>
        )}
      </div>

      <div>
        <IconEdit
          className={styles.tokenIcon}
          size="M"
          onClick={() => {
            setEditModalActive(true);
          }}
        />

        <IconClose
          className={styles.tokenIcon}
          size="M"
          onClick={() => setDeleteModalActive(true)}
        />
      </div>
    </div>
  );
};

const CreateTokenModal: React.FC<{
  isActive: boolean;
  close: () => void;
}> = ({ isActive, close }) => {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<UserToken>({
    value: '',
    domain: '',
    title: '',
    due: '',
  });

  const updatePayload = <T extends keyof UserToken>(key: T, value: UserToken[T]) => {
    setPayload({
      ...payload,
      [key]: value,
    });
  };

  const { run: create } = useAsync({
    deferFn: async ([token]) => {
      if (user) {
        const { tokens, id } = user;
        const created = {
          ...token,
          due: token.due ? toISOString(token.due) : '',
        };
        tokens.push(created);
        setLoading(true);
        await updateToken(id, tokens);
        setUser({ ...user, tokens });
      }
    },
    onResolve() {
      setLoading(false);
      notifier.success({ message: 'Success', description: 'Token created' });
      setPayload({
        value: '',
        domain: '',
        title: '',
        due: '',
      });
      close();
    },
    onReject(err) {
      setLoading(false);
      console.error('Error occured creating token', err);
      notifier.error({ message: 'Error', description: 'Failed to create token' });
      user?.tokens.pop();
      setPayload({
        value: '',
        domain: '',
        title: '',
        due: '',
      });
      close();
    },
  });

  if (!isActive || !user) return <></>;

  return (
    <Modal overlayClassName={styles.modalOverlay} onClose={close} className={styles.modal}>
      <div className={styles.addTokenForm}>
        <Title className={styles.tokensTitle} level={2}>
          New Token
        </Title>
        <InputText
          className={styles.addTokenInput}
          size="L"
          label="Label"
          placeholder="Full-Access-Token"
          value={payload.title}
          onChange={({ currentTarget: { value } }) => updatePayload('title', value)}
        />

        <InputText
          className={styles.addTokenInput}
          size="L"
          label="Host"
          placeholder="github.com"
          value={payload.domain}
          onChange={({ currentTarget: { value } }) => updatePayload('domain', value)}
        />

        <InputText
          className={styles.addTokenInput}
          size="L"
          label="Token"
          value={payload.value}
          type="password"
          placeholder="y0urt0k3n"
          onChange={({ currentTarget: { value } }) => updatePayload('value', value)}
        />

        <InputText
          className={styles.addTokenInput}
          size="L"
          label="ExpiresAt"
          value={payload.due}
          type="date"
          placeholder="Enter expiry date..."
          onChange={({ currentTarget: { value } }) => updatePayload('due', value)}
        />

        <div className={styles.addTokenButtons}>
          <Button className={styles.addTokenButton} size="L" variant="ghost" onClick={close}>
            Cancel
          </Button>
          <Button
            className={styles.addTokenButton}
            size="L"
            variant="access"
            disabled={loading}
            onClick={() => create(payload)}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const GitTokensPage: React.FC<{ className?: string }> = ({ className }) => {
  const [isActive, setIsActive] = useState(false);
  const { user } = useAuth();

  const resolveTokenBackgroundColor = (expiresAtStr?: string): string => {
    const defaultColor = '';

    if (!expiresAtStr) return defaultColor;

    const expiresAt = new Date(expiresAtStr);
    const warningColor = '#fffaeb';
    const dangerColor = '#feecf0';

    const expiryWarningMs = 1e3 * 60 * 60 * 24;
    const now = Date.now();

    if (expiresAt.getTime() - now <= 0) {
      return dangerColor;
    }

    if (expiresAt.getTime() - now <= expiryWarningMs) {
      return warningColor;
    }

    return defaultColor;
  };

  return (
    <Plate className={cn(styles.container, className)}>
      <Title className={styles.title} level={1}>
        Git Tokens
      </Title>
      <section className={styles.iconAndForm}>
        <CreateTokenModal isActive={isActive} close={() => setIsActive(false)} />
        {user && user.tokens && user.tokens.length > 0 && (
          <div className={styles.tokensList}>
            {user.tokens.map((token, index) => (
              <div
                style={{
                  width: '100%',
                  backgroundColor: resolveTokenBackgroundColor(token.due),
                }}
              >
                <TokenListItem token={token} index={index} key={index} />
                <hr />
              </div>
            ))}
          </div>
        )}

        {user && user.tokens && !user.tokens.length && <h2>You don't have any tokens yet.</h2>}
      </section>
      <Button size="L" onClick={() => setIsActive(true)}>
        Add New Token
      </Button>
    </Plate>
  );
};

export default GitTokensPage;
