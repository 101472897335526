import { createContext, useState, useEffect, useContext, useMemo, PropsWithChildren } from 'react';
import { getCurrentUser, User } from 'api';
import { authRedirect } from 'api/helpers';

type UserState = User | null;

type AuthContextType = {
  user: UserState;
  setUser: (user: UserState) => void;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
});

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<UserState>(null);

  const getUser = async () => {
    getCurrentUser()
      .then((user) => setUser(user))
      .catch((error) => {
        if (error.status === 401) {
          const redirectUrl = encodeURIComponent(window.location.href);
          authRedirect(redirectUrl);
        }
        throw error;
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const auth = useMemo(
    () => ({
      user,
      setUser,
      getUser,
    }),
    [user, setUser],
  );

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
