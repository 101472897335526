import { FunctionComponent } from 'react';
import {
  Plate,
  Title,
  Button,
  IconList,
  IconGrid,
  InputText,
  IconSearch,
  Select,
} from '@platformeco/ui-components';
import cn from 'classnames';
import { ViewMode, SortMode } from 'pages/Main';
import styles from './ControlPanel.module.css';

const ControlPanel: FunctionComponent<ControlPanelProps> = (props) => {
  return (
    <Plate className={styles.controlPanel}>
      <ControlPanelText />
      <div className={styles.pad} />
      <ControlPanelButtons {...props} />
      <ControlPanelSearch {...props} />
      <ControlPanelSort {...props} />
    </Plate>
  );
};

interface ControlPanelProps {
  viewMode: ViewMode;
  onViewModeChange: (v: ViewMode) => void;
  sortMode: SortMode;
  onSortModeChange: (v: SortMode) => void;
  searchValue: string;
  onSearchChange: (v: string) => void;
}

const ControlPanelText = () => (
  <div className={styles.text}>
    <Title level={1}>My channels</Title>
    <span className={styles.env}>Environment: Production</span>
    <span className={styles.period}>Period: Today</span>
  </div>
);

const ControlPanelButtons: FunctionComponent<
  Pick<ControlPanelProps, 'viewMode' | 'onViewModeChange'>
> = ({ onViewModeChange, viewMode }) => {
  const isList = viewMode === ViewMode.LIST;
  const isGrid = viewMode === ViewMode.GRID;

  return (
    <>
      <Button
        className={cn(styles.btn, { [styles.activeBtn]: isList })}
        onClick={() => onViewModeChange(ViewMode.LIST)}
        size="M"
        variant="ghost"
      >
        <IconList size="M" />
      </Button>
      <Button
        className={cn(styles.gridBtn, styles.btn, { [styles.activeBtn]: isGrid })}
        onClick={() => onViewModeChange(ViewMode.GRID)}
        size="M"
        variant="ghost"
      >
        <IconGrid size="M" />
      </Button>
    </>
  );
};

const ControlPanelSearch: FunctionComponent<
  Pick<ControlPanelProps, 'searchValue' | 'onSearchChange'>
> = ({ searchValue, onSearchChange }) => (
  <InputText
    className={styles.search}
    label="Search"
    icon={<IconSearch size="M" />}
    value={searchValue}
    onChange={({ currentTarget: { value } }) => onSearchChange(value)}
  />
);

const ControlPanelSort: FunctionComponent<
  Pick<ControlPanelProps, 'sortMode' | 'onSortModeChange'>
> = ({ onSortModeChange, sortMode }) => (
  <Select
    className={styles.sortBy}
    label="Sort by"
    options={Object.values(SortMode)}
    value={sortMode}
    onChange={({ target: { value } }) => onSortModeChange(value)}
    withBlank={false}
  />
);

export default ControlPanel;
