import { FunctionComponent, useState } from 'react';
import { Async } from 'react-async';
import cn from 'classnames';
import createPersistedState from 'use-persisted-state';
import { ApplicationInfo } from 'api';
import { getUserApps } from 'api/auth';
import BigError from 'components/BigError';
import ChannelsList from 'components/ChannelsList';
import ControlPanel from 'components/ControlPanel';
import styles from './Main.module.css';

const useViewModeState = createPersistedState('platformeco-channels-list-view-mode');
const useSortModeState = createPersistedState('platformeco-channels-list-sort-mode');

export enum ViewMode {
  LIST = 'list',
  GRID = 'grid',
}

export enum SortMode {
  NAME = 'Name: A → Z',
  NAME_REVERSE = 'Name: Z → A',
}

const Main: FunctionComponent<{ className?: string }> = ({ className }) => {
  const [viewMode, setViewMode] = useViewModeState<ViewMode>(ViewMode.GRID);
  const [sortMode, setSortMode] = useSortModeState<SortMode>(SortMode.NAME);
  const [searchValue, setSearchValue] = useState('');

  const sortAndFilter = (channels: ApplicationInfo[]) => {
    const filtered = channels.filter((channel) => channel.title.includes(searchValue));
    if (sortMode === SortMode.NAME) {
      return filtered.sort((channel1, channel2) => channel1.title.localeCompare(channel2.title));
    }

    return filtered.sort((channel1, channel2) => channel2.title.localeCompare(channel1.title));
  };

  const onReject = (err: Error) => console.error('Error occured during user channels loading', err);

  return (
    <div className={cn(styles.container, className)}>
      <ControlPanel
        viewMode={viewMode}
        onViewModeChange={setViewMode}
        sortMode={sortMode}
        onSortModeChange={setSortMode}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
      />
      <Async promiseFn={getUserApps} onReject={onReject}>
        <Async.Fulfilled>
          {(apps: ApplicationInfo[]) => (
            <ChannelsList className={styles.list} apps={sortAndFilter(apps)} viewMode={viewMode} />
          )}
        </Async.Fulfilled>
        <Async.Pending>
          <ChannelsList className={styles.list} viewMode={viewMode} loading />
        </Async.Pending>
        <Async.Rejected persist>
          {(_, { reload }) => (
            <BigError className={styles.error} action="Retry" onAction={reload}>
              Couldn't load channels list
            </BigError>
          )}
        </Async.Rejected>
      </Async>
    </div>
  );
};

export default Main;
