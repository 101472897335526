import { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Header, ThemeContext, Toggler } from '@platformeco/ui-components';
import UserMenu from 'components/UserMenu';
import styles from './AppHeader.module.css';
import logo from './logo.png';

const AppHeader: FunctionComponent = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const isDarkTheme = theme === 'dark';
  const toggleTheme = () => setTheme(isDarkTheme ? 'light' : 'dark');

  return (
    <Header className={styles.header}>
      <Link to="/" className={styles.logo}>
        <img src={logo} alt="Platformeco Logo" />
      </Link>
      <div className={styles.pad} />
      <UserMenu />
      <Toggler isTheme className={styles.themeToggler} value={isDarkTheme} onChange={toggleTheme} />
    </Header>
  );
};

export default AppHeader;
