import { gql } from '@apollo/client';

export interface NewTelemetryResponse {
  definitions: {
    activeAvg: number;
  };
  dashboard: {
    rps: {
      avg: number;
    };
    latency: {
      p99: number;
      aggregatedVal: number;
    };
    statusCounts: {
      successCount: number;
      clientErrors: number;
      serverErrors: number;
    };
  };
}

export const getNewDashboardQuery = gql`
  query GetDashboard($from: DateTime!, $to: DateTime!, $service: String!) {
    definitions(time: { from: $from, to: $to }) {
      activeAvg
    }
    dashboard(
      time: { from: $from, to: $to }
      service: $service
      operation: "all"
      mode: INCOMING
      latencyAggregationKind: AVG
    ) {
      latency {
        p99
        aggregatedVal
      }
      statusCounts {
        successCount
        clientErrors
        serverErrors
      }
      rps {
        avg
      }
    }
  }
`;
