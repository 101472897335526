import wretch from 'wretch';
import { getConfig } from './config';

export const client = wretch()
  .options({ credentials: 'include' })
  .catcher(401, (err) => {
    authRedirect(window.location.href);
    throw err;
  });

export async function authRedirect(to: string) {
  const config = await getConfig();
  const redirectURI = encodeURIComponent(to);
  window.location.href = `${config.auth}/signin?redirect_url=${redirectURI}`;
}

export function getValueOrRetrieveByDefault<T>(value: T | undefined, valueByDefault: T) {
  if (!value) return valueByDefault;
  return value;
}
