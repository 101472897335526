import { FunctionComponent, useEffect, useState } from 'react';
import Async from 'react-async';
import ContentLoader from 'react-content-loader';
import { useRouteMatch, Link } from 'react-router-dom';
import { IconProfile, IconError } from '@platformeco/ui-components';
import cn from 'classnames';
import { getCurrentUser, logout } from 'api';
import { useAuth } from 'components/Auth';
import styles from './UserMenu.module.css';

const UserMenu: FunctionComponent = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const handler = () => setMenuVisible(false);
    if (menuVisible) {
      document.addEventListener('click', handler);
    }
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [menuVisible, setMenuVisible]);

  const onReject = (err: Error) => console.error('Error occured during current user loading', err);

  return (
    <Async promiseFn={getCurrentUser} onReject={onReject}>
      <Async.Resolved>
        {() => (
          <div
            className={cn(styles.container, { [styles.opened]: menuVisible })}
            onClick={() => setMenuVisible(!menuVisible)}
          >
            <IconProfile size="M" className={styles.userIcon} />
            <span className={styles.userName}>{user?.name}</span>
            {menuVisible && <MenuLinks />}
          </div>
        )}
      </Async.Resolved>
      <Async.Rejected>
        <UserMenuError />
      </Async.Rejected>
      <Async.Pending>
        <UserMenuLoading />
      </Async.Pending>
    </Async>
  );
};

function MenuLinks() {
  const mainMatch = useRouteMatch({ path: '/', exact: true });
  const settingsMatch = useRouteMatch({ path: '/settings', exact: true });
  const tokensMatch = useRouteMatch({ path: '/tokens', exact: true });
  const { setUser } = useAuth();

  const signOut = async () => {
    await logout();
    setUser(null);
  };

  return (
    <div className={styles.menu}>
      <Link to="/settings" className={cn(styles.item, { [styles.active]: settingsMatch })}>
        Settings
      </Link>
      <Link to="/tokens" className={cn(styles.item, { [styles.active]: tokensMatch })}>
        Tokens
      </Link>
      <Link to="/" className={cn(styles.item, { [styles.active]: mainMatch })}>
        My channels
      </Link>
      <div className={styles.item} onClick={signOut}>
        Logout
      </div>
    </div>
  );
}

function UserMenuError() {
  return (
    <>
      <IconError size="M" className={styles.errorIcon} />
      <span className={styles.errorText}>Couldn't load user info</span>
    </>
  );
}

function UserMenuLoading() {
  return (
    <>
      <IconProfile size="M" className={styles.userIcon} />
      <ContentLoader
        width={150}
        height={20}
        backgroundColor="#ebebeb55"
        foregroundColor="#f0f0f022"
      >
        <rect rx="7" ry="7" width="150" height="20" />
      </ContentLoader>
    </>
  );
}

export default UserMenu;
