import { FunctionComponent } from 'react';
import cn from 'classnames';
import { Status } from 'api';
import styles from './StatusBadge.module.css';

const StatusBadge: FunctionComponent<Props> = ({ value, className }) => (
  <div className={cn(styles.container, styles[value], className)}>{value}</div>
);

interface Props {
  value: Status;
  className?: string;
}

export default StatusBadge;
