import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { EnvironmentInfo } from 'api';
import { getTelemetryInfo, TelemetryInfo } from 'api/telemetry';
import { getNewDashboardQuery, NewTelemetryResponse } from './new/gql';
import { getOldDashboardQuery, OldTelemetryResponse } from './old/gql';

type TelemetryResponse = OldTelemetryResponse | NewTelemetryResponse;

export function useTelemetryStats(channelName: string, env: EnvironmentInfo) {
  const service = channelName.toUpperCase();
  const [monitoringInfo, setMonitoringInfo] = useState<TelemetryInfo>();

  useEffect(() => {
    getTelemetryInfo(channelName, env.endpoint)
      .then((info) => setMonitoringInfo(info))
      .catch((err) => {
        console.error(err);
      });
  }, [channelName, env]);

  const period = useMemo(() => {
    const from = new Date();
    from.setHours(from.getHours() - 3);

    const to = new Date();

    return {
      from,
      to,
    };
  }, []);

  const query =
    monitoringInfo && monitoringInfo.version ? getNewDashboardQuery : getOldDashboardQuery;
  const { data, loading, error } = useQuery<TelemetryResponse>(query, {
    variables: {
      service,
      from: period.from,
      to: period.to,
    },
  });

  return {
    data,
    error,
    loading,
  };
}
