import { Component } from 'react';
import BigError from 'components/BigError';
import styles from './Error.module.css';

export default class ErrorPage extends Component<unknown, { error: unknown }> {
  constructor(props: unknown) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: unknown) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      const err = this.state.error;
      return (
        <BigError action="Reload" onAction={this.reload} className={styles.container}>
          {err instanceof Error
            ? err.toString()
            : typeof err === 'string'
            ? err
            : 'Something went wrong'}
        </BigError>
      );
    }
    return this.props.children;
  }

  reload() {
    window.location.reload();
  }
}
