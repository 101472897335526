import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { getConfig } from './config';
import { client } from './deployment';

export type TelemetryInfo = {
  version?: string;
  prometheusStatus: boolean;
  elasticStatus: boolean;
};

export async function getTelemetryURL(name: string, env?: string) {
  if (!env) {
    const config = await getConfig();
    return config.defaultTelemetry.replace(/{{channel}}/g, name);
  }
  const monitoringURL = env?.replace('control-center-backend', 'telemetry-server');
  return monitoringURL;
}

export async function getTelemetryInfo(name: string, env?: string): Promise<TelemetryInfo> {
  const uri = await getTelemetryURL(name, env);
  return await client.url(`${uri}/healthcheck`).get();
}

export async function createTelemetryClient(name: string, env?: string) {
  const uri = await getTelemetryURL(name, env);

  const link = createHttpLink({
    uri,
    credentials: 'include',
  });

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}
