import { FunctionComponent, useState } from 'react';
import Async, { DeferFn, PromiseFn, useAsync } from 'react-async';
import ContentLoader from 'react-content-loader';
import {
  IconProfile,
  Plate,
  Title,
  InputText,
  Button,
  notifier,
  IconError,
} from '@platformeco/ui-components';
import cn from 'classnames';
import { getCurrentUser, getUserSettings, setUserSettings, Settings } from 'api';
import { useAuth } from 'components/Auth';
import styles from './Settings.module.css';

const getSettings: PromiseFn<Settings & { id: string }> = async () => {
  const { id } = await getCurrentUser();
  const settings = await getUserSettings(id);
  return { ...settings, id };
};

const SettingsPage: FunctionComponent<{ className?: string }> = ({ className }) => {
  const { user, setUser } = useAuth();

  const saveSettings: DeferFn<void> = async ([data]) => {
    if (user) {
      await setUserSettings(user.id, data);
      setUser({ ...user, ...data });
    }
  };

  const { run: save, isLoading: isSaving } = useAsync({
    deferFn: saveSettings,
    onResolve() {
      notifier.success({ message: 'Success', description: 'Settings saved' });
    },
    onReject(err) {
      console.error('Error occured during user settings saving', err);
      notifier.error({ message: 'Error', description: 'Settings update failed' });
    },
  });

  const onGetSettingsReject = (err: Error) =>
    console.error('Error occured during user settings loading', err);

  return (
    <Plate className={cn(styles.container, className)}>
      <Title className={styles.title} level={1}>
        Settings
      </Title>
      <section className={styles.iconAndForm}>
        <aside>
          <div className={styles.icon}>
            <IconProfile size="XL" />
          </div>
        </aside>
        <Async promiseFn={getSettings} onReject={onGetSettingsReject}>
          <Async.Fulfilled>
            {(data: Settings) => <SettingsForm {...data} isSaving={isSaving} onSumbit={save} />}
          </Async.Fulfilled>
          <Async.Rejected>
            <SettingsFormError />
          </Async.Rejected>
          <Async.Pending>
            <SettingsFormLoading />
          </Async.Pending>
        </Async>
      </section>
    </Plate>
  );
};

function SettingsForm(
  props: Settings & {
    isSaving?: boolean;
    onSumbit?: (v: Settings) => void;
  },
) {
  const [name, setDisplayedName] = useState(props.name);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (props.onSumbit) props.onSumbit({ name });
  };
  return (
    <form className={styles.form}>
      <InputText
        size="L"
        label="Displayed name"
        required
        disabled={props.isSaving}
        value={name}
        onChange={({ currentTarget: { value } }) => setDisplayedName(value)}
      />

      <Button className={styles.saveBtn} size="L" disabled={props.isSaving} onClick={handleSubmit}>
        Save Changes
      </Button>
    </form>
  );
}

function SettingsFormError() {
  return (
    <div className={styles.formError}>
      <IconError size="XXL" />
      <Title level={3}>Couldn't load user settings</Title>
    </div>
  );
}

function SettingsFormLoading() {
  return (
    <ContentLoader width={668} height={309} backgroundColor="#ebebeb55" foregroundColor="#f0f0f022">
      <rect rx="7" ry="7" x="0" y="15" width="584" height="48" />
      <rect rx="7" ry="7" x="0" y="102" width="584" height="48" />
      <rect rx="7" ry="7" x="0" y="189" width="584" height="48" />
      <rect rx="7" ry="7" x="0" y="261" width="168" height="48" />
    </ContentLoader>
  );
}

export default SettingsPage;
