import ContentLoader from 'react-content-loader';
import { IconError, Title } from '@platformeco/ui-components';
import { EnvironmentInfo } from 'api';
import styles from './MonitoringStats.module.css';

export interface Props {
  name: string;
  env: EnvironmentInfo;
  controlCenterURL?: string;
}

export function MonitoringStatsError() {
  return (
    <div className={styles.error}>
      <IconError size="XL" />
      <Title level={4}>Couldn't load monitoring statistics</Title>
    </div>
  );
}

function MonitoringStatsLoadingBlock() {
  return (
    <ContentLoader
      className={styles.block}
      width={70}
      height={46}
      backgroundColor="#ebebeb55"
      foregroundColor="#f0f0f022"
    >
      <rect x="0" y="0" rx="7" ry="7" width="70" height="46" />
    </ContentLoader>
  );
}

export function MonitoringStatsLoading() {
  return (
    <section className={styles.container}>
      <MonitoringStatsLoadingBlock />
      <MonitoringStatsLoadingBlock />
      <MonitoringStatsLoadingBlock />
      <MonitoringStatsLoadingBlock />
      <MonitoringStatsLoadingBlock />
      <MonitoringStatsLoadingBlock />
      <MonitoringStatsLoadingBlock />
    </section>
  );
}

export function formatFloat(f: number, maxNumbers: number) {
  if (Math.round((f % 1) * 10 ** maxNumbers) === 0) {
    return Math.round(f);
  } else {
    return f.toFixed(maxNumbers);
  }
}

export function prettyDuration(ms: number): string {
  let val = ms;
  let suffix = 'ms';
  if (ms >= 1000 * 60 * 60) {
    val = ms / (1000 * 60 * 60);
    suffix = 'h';
  } else if (ms >= 1000 * 60) {
    val = ms / (1000 * 60);
    suffix = 'min';
  } else if (ms > 1000) {
    val = ms / 1000;
    suffix = 'sec';
  }
  return formatFloat(val, 2) + suffix;
}

export function prettyCount(n: number): string {
  let val = n;
  let suffix = '';
  if (n >= 1e9) {
    val = n / 1e9;
    suffix = 'b';
  } else if (n >= 1e6) {
    val = n / 1e6;
    suffix = 'm';
  } else if (n >= 1000) {
    val = n / 1000;
    suffix = 'k';
  }
  return formatFloat(val, 2) + suffix;
}
