import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import { Button } from '@platformeco/ui-components';
import cn from 'classnames';
import { ApplicationInfo } from 'api';
import styles from './RightPart.module.css';

type LinkButtonProps = {
  url?: string;
  title: string;
};

const LinkButton: FunctionComponent<LinkButtonProps> = ({ url, title }) => (
  <a
    target="_blank"
    rel="noreferrer"
    href={url}
    className={cn({ [styles.channelLinkDisabled]: !url })}
  >
    <Button className={styles.channelLinkButton} variant="ghost" disabled={!url}>
      {title}
    </Button>
  </a>
);

const RightPart: FunctionComponent<Props> = ({ channel, vertical }: Props) => {
  return (
    <aside className={cn(styles.container, { [styles.vertical]: vertical })}>
      <LinkButton title="View In CC" url={channel.controlCenterURL} />
      <LinkButton title="Open Repository" url={channel.repositoryURL} />
    </aside>
  );
};

interface Props {
  channel: ApplicationInfo;
  vertical?: boolean;
}

export function RightPartLoading({ vertical }: { vertical?: boolean }) {
  if (vertical) {
    return (
      <aside className={cn(styles.container, styles.vertical)}>
        <ContentLoader
          width={564}
          height={32}
          style={{ width: '100%' }}
          backgroundColor="#ebebeb55"
          foregroundColor="#f0f0f022"
        >
          <rect x="9%" y="0" rx="7" ry="7" width="32%" height="32" />
          <rect x="59%" y="0" rx="7" ry="7" width="32%" height="32" />
        </ContentLoader>
      </aside>
    );
  }
  return (
    <aside className={styles.container}>
      <ContentLoader
        width={144}
        height={128}
        backgroundColor="#ebebeb55"
        foregroundColor="#f0f0f022"
      >
        <rect x="0" y="16" rx="7" ry="7" width="144" height="32" />
        <rect x="0" y="80" rx="7" ry="7" width="144" height="32" />
      </ContentLoader>
    </aside>
  );
}

export default RightPart;
