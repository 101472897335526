import { FunctionComponent } from 'react';
import cn from 'classnames';
import { formatFloat, prettyCount, prettyDuration } from '../common';
import styles from '../MonitoringStats.module.css';
import { OldTelemetryResponse } from './gql';

const OldMonitoringStats: FunctionComponent<OldTelemetryResponse> = ({ dashboard }) => {
  return (
    <section className={styles.container}>
      <div className={cn(styles.block, styles.latency)}>
        <span>{prettyDuration(dashboard.latency.max)}</span>
        <span>MAX LATENCY</span>
      </div>
      <div className={cn(styles.block, styles.latency)}>
        <span>{prettyDuration(dashboard.latency.avg)}</span>
        <span>AVG LATENCY</span>
      </div>
      <div className={cn(styles.block, styles.serverErrors)}>
        <span>{prettyCount(dashboard.statusCounts.serverErrors)}</span>
        <span>SERVER ERRORS</span>
      </div>
      <div className={cn(styles.block, styles.clientErrors)}>
        <span>{prettyCount(dashboard.statusCounts.clientErrors)}</span>
        <span>CLIENT ERRORS</span>
      </div>
      <div className={cn(styles.block, styles.success)}>
        <span>{prettyCount(dashboard.statusCounts.successCount)}</span>
        <span>SUCCESS</span>
      </div>
      <div className={cn(styles.block, styles.rps)}>
        <span>{prettyCount(dashboard.rps.max)}</span>
        <span>MAX RPS</span>
      </div>
      <div className={cn(styles.block, styles.rps)}>
        <span>{formatFloat(dashboard.rps.avg, 2)}</span>
        <span>AVG RPS</span>
      </div>
    </section>
  );
};

export default OldMonitoringStats;
