import { gql } from '@apollo/client';

export interface OldTelemetryResponse {
  dashboard: {
    interval: number;
    rps: {
      avg: number;
      max: number;
    };
    latency: {
      avg: number;
      max: number;
    };
    statusCounts: {
      successCount: number;
      clientErrors: number;
      serverErrors: number;
    };
  };
}

export const getOldDashboardQuery = gql`
  query GetDashboard($from: DateTime!, $to: DateTime!, $service: String!) {
    dashboard(time: { from: $from, to: $to }, service: $service, operation: "all", mode: INCOMING) {
      interval
      rps {
        avg
        max
      }
      latency {
        avg
        max
      }
      statusCounts {
        successCount
        clientErrors
        serverErrors
      }
    }
  }
`;
