import wretch from 'wretch';
import { throttlingCache } from 'wretch-middlewares';
import { getConfig, getConfigURLs } from './config';
import { getValueOrRetrieveByDefault } from './helpers';

const envsCache = throttlingCache({ throttle: 3600e3 });
const statsCache = throttlingCache({
  throttle: 60e3,
  key(urlString) {
    let url = new URL(urlString);
    url.searchParams.delete('from');
    url.searchParams.delete('to');
    return url.toString();
  },
});
const jobsRunsCache = throttlingCache({ throttle: 10e3 });

export const client = wretch()
  .options({ credentials: 'include' })
  .resolve((res) => res.json());

export async function getEnvs(
  name: string,
  controlCenterURL?: string,
): Promise<Array<EnvironmentInfo>> {
  const deployURL = await getDeployURL(name, controlCenterURL);
  const res: EnvironmentInfo[] = await client
    .url(`${deployURL}/environments`)
    .middlewares([envsCache])
    .get();

  return res.map((e) => ({ ...e, isProd: e.isDeployable }));
}

export async function getProcessingModuleStats(
  name: string,
  env: EnvironmentInfo,
  from: Date,
  to: Date,
  controlCenterURL?: string,
): Promise<ProcessingModuleStats> {
  const deployURL = await getDeployURL(name, controlCenterURL);
  const baseURL = env.endpoint || deployURL;
  const res: ProcessingModuleStats = await client
    .middlewares([statsCache])
    .url(`${baseURL}/environments/${env.id}/modules/processing/stats`)
    .query({ from: from.getTime(), to: to.getTime(), environment: env.id })
    .get();
  const runs = await getJobRuns(baseURL, env.id);
  runs.sort((a, b) => b.time - a.time);
  res.lastDeploy = runs.length ? new Date(runs[0].time) : undefined;
  return res;
}

export async function getJobRuns(baseUrl: string, envID: string): Promise<Array<JobRun>> {
  return await client
    .middlewares([jobsRunsCache])
    .url(`${baseUrl}/jobs/runs`)
    .query({ id: envID })
    .get();
}

export type Status = 'ok' | 'degraded' | 'down';

export interface ProcessingModuleStats {
  status: Status;
  link: string;
  restartsCount: number;
  instances: number;
  totalInstances?: number;
  traffic: {
    incoming: number;
    outgoing: number;
  };
  version: string;
  channelVersion: string;
  lastDeploy?: Date;
}

export interface EnvironmentInfo {
  id: string;
  isDeployable: boolean;
  isProd: boolean; // temp stub, equals to isDeployable
  title: string;
  canaryName?: string;
  endpoint?: string;
}

export interface JobRun {
  id: string;
  commitHash?: string;
  initiator?: string;
  building: boolean;
  description?: string;
  time: number;
  url: string;
  status: 'SUCCESS' | 'ABORTED' | 'FAILURE' | 'IN PROGRESS';
  duration?: number;
  environment?: string;
}

export async function getDeployURL(name: string, controlCenterURL?: string) {
  const config = await getConfig();
  const configURLs = await getConfigURLs(config, controlCenterURL);
  const deployURL = getValueOrRetrieveByDefault<string>(configURLs?.deploy, config.defaultDeploy);
  return deployURL.replace(/{{channel}}/g, name);
}
