import React, { FunctionComponent } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, Theme } from '@platformeco/ui-components';
import createPersistedState from 'use-persisted-state';
import AppHeader from 'components/AppHeader';
import { AuthProvider } from 'components/Auth';
import AuthRedirect from 'pages/AuthRedirect';
import Error from 'pages/Error';
import GitTokensPage from 'pages/GitTokens';
import Main from 'pages/Main';
import NotFound from 'pages/NotFound';
import Settings from 'pages/Settings';
import history from './BrowserHistory';

const useThemeState = createPersistedState('platformeco-theme');

const App: FunctionComponent = () => {
  const [theme, setTheme] = useThemeState<Theme>('light');

  return (
    <Error>
      <ThemeProvider value={{ theme, setTheme }}>
        <AuthProvider>
          <Router history={history}>
            <Switch>
              <Route exact path="/">
                <AppHeader />
                <Main />
              </Route>
              <Route exact path="/settings">
                <AppHeader />
                <Settings />
              </Route>
              <Route exact path="/tokens">
                <AppHeader />
                <GitTokensPage />
              </Route>
              <Route exact path="/auth_redirect">
                <AuthRedirect />
              </Route>
              <Route path="*">
                <AppHeader />
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </Error>
  );
};

export default App;
