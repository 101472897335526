import { FunctionComponent } from 'react';
import cn from 'classnames';
import ChannelCard, { ChannelCardLoading } from 'components/ChannelCard';
import { ViewMode } from 'pages/Main';
import { ApplicationInfo } from '../../api';
import styles from './ChannelsList.module.css';

const ChannelsList: FunctionComponent<Props | LoadingProps> = (props) => (
  <main
    className={cn(styles.container, props.className, {
      [styles.grid]: props.viewMode === ViewMode.GRID,
      [styles.list]: props.viewMode === ViewMode.LIST,
    })}
  >
    {'loading' in props
      ? [...Array(4)].map((_, i) => (
          <ChannelCardLoading key={i} vertical={props.viewMode === ViewMode.GRID} />
        ))
      : props.apps.map((app) => (
          <ChannelCard key={app.id} app={app} vertical={props.viewMode === ViewMode.GRID} />
        ))}
  </main>
);

interface Props {
  apps: ApplicationInfo[];
  viewMode: ViewMode;
  className?: string;
}

interface LoadingProps {
  viewMode: ViewMode;
  className?: string;
  loading: true;
}

export default ChannelsList;
