import { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import { Title, Select, IconView } from '@platformeco/ui-components';
import cn from 'classnames';
import styles from './LeftPart.module.css';

const LeftPart: FunctionComponent<Props> = (props: Props) => {
  const { title, envs, currentEnv, onEnvChange, vertical } = props;

  return (
    <section className={cn(styles.container, { [styles.vertical]: vertical })}>
      <header className={styles.iconAndTitle}>
        <div className={styles.icon}>
          <IconView size="M" />
        </div>
        <Title level={3}>{title}</Title>
      </header>
      {envs.length > 1 && (
        <Select
          className={styles.envs}
          options={envs}
          value={currentEnv}
          onChange={({ target: { value } }) => onEnvChange(value)}
          withBlank={false}
        />
      )}
    </section>
  );
};

export function LeftPartLoading({ vertical }: { vertical?: boolean }) {
  if (vertical) {
    return (
      <aside className={cn(styles.container, styles.vertical)}>
        <ContentLoader
          width={644}
          height={40}
          style={{ width: '100%' }}
          backgroundColor="#ebebeb55"
          foregroundColor="#f0f0f022"
        >
          <circle cx="20" cy="20" r="20" />
          <rect x="56" y="5" rx="7" ry="7" width="100" height="32" />
          <rect x="172" y="4" rx="7" ry="7" width="202" height="34" />
        </ContentLoader>
      </aside>
    );
  }
  return (
    <aside className={styles.container}>
      <ContentLoader
        width={284}
        height={160}
        backgroundColor="#ebebeb55"
        foregroundColor="#f0f0f022"
      >
        <circle cx="20" cy="40" r="20" />
        <rect x="56" y="24" rx="7" ry="7" width="100" height="32" />
        <rect x="0" y="94" rx="7" ry="7" width="202" height="34" />
      </ContentLoader>
    </aside>
  );
}

interface Props {
  title: string;
  envs: string[];
  currentEnv: string;
  onEnvChange: (env: string) => void;
  vertical?: boolean;
}

export default LeftPart;
