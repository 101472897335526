import { FunctionComponent } from 'react';
import cn from 'classnames';
import { formatFloat, prettyCount, prettyDuration } from '../common';
import styles from '../MonitoringStats.module.css';
import { NewTelemetryResponse } from './gql';

const NewMonitoringStats: FunctionComponent<NewTelemetryResponse> = ({
  dashboard,
  definitions,
}) => {
  return (
    <section className={styles.container}>
      <div className={cn(styles.block, styles.latency)}>
        <span>{prettyDuration(dashboard.latency.p99)}</span>
        <span>P99 LATENCY</span>
      </div>
      <div className={cn(styles.block, styles.latency)}>
        <span>{prettyDuration(dashboard.latency.aggregatedVal)}</span>
        <span>MEDIAN LATENCY</span>
      </div>
      <div className={cn(styles.block, styles.serverErrors)}>
        <span>{prettyCount(dashboard.statusCounts.serverErrors)}</span>
        <span>SERVER ERRORS</span>
      </div>
      <div className={cn(styles.block, styles.clientErrors)}>
        <span>{prettyCount(dashboard.statusCounts.clientErrors)}</span>
        <span>CLIENT ERRORS</span>
      </div>
      <div className={cn(styles.block, styles.success)}>
        <span>{prettyCount(dashboard.statusCounts.successCount)}</span>
        <span>SUCCESS</span>
      </div>
      <div className={cn(styles.block, styles.rps)}>
        <span>{prettyCount(dashboard.rps.avg)}</span>
        <span>AVG RPS</span>
      </div>
      <div className={cn(styles.block, styles.rps)}>
        <span>{formatFloat(definitions.activeAvg, 2)}</span>
        <span>ACTIVE DEFINITIONS AVG</span>
      </div>
    </section>
  );
};

export default NewMonitoringStats;
