import wretch from 'wretch';
import { throttlingCache } from 'wretch-middlewares';

export type ConfigCluster = {
  path: string;
  deployURL: string;
  telemetryURL: string;
};

export type Config = {
  auth: string;
  clusters: {
    [name: string]: ConfigCluster;
  };
  defaultDeploy: string;
  defaultTelemetry: string;
};

export type ConfigURLs = {
  deploy: string;
  telemetry: string;
};

const configCache = throttlingCache({ throttle: 3600e3 });

export async function getConfig(): Promise<Config> {
  return await wretch('/config.json').middlewares([configCache]).get().json();
}

export async function getConfigURLs(
  config: Config,
  controlCenterURL?: string,
): Promise<ConfigURLs | undefined> {
  const cluster = Object.values(config.clusters).find((cluster) => {
    return controlCenterURL?.includes(cluster.path);
  });
  if (!cluster) return;
  return {
    deploy: cluster.deployURL,
    telemetry: cluster.telemetryURL,
  };
}
