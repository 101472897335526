import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Title } from '@platformeco/ui-components';
import { authRedirect } from 'api/helpers';
import styles from './AuthRedirect.module.css';
import { ReactComponent as RedirectImage } from './redirect.svg';

const AuthRedirect: FunctionComponent = () => {
  const location = useLocation();
  useEffect(() => {
    authRedirect(new URLSearchParams(location.search).get('back') ?? '');
  });
  return (
    <main className={styles.container}>
      <RedirectImage />
      <Title level={1}>You are being redirected to the login page</Title>
    </main>
  );
};

export default AuthRedirect;
