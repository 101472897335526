import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { createTelemetryClient } from '../../../../api/telemetry';

interface Props {
  name: string;
  env?: string;
}

export const TelemetryConnection: React.FC<Props> = ({ children, env, name }) => {
  const [client, setClient] = useState<ApolloClient<unknown>>();

  useEffect(() => {
    (async () => {
      const client = await createTelemetryClient(name, env);
      setClient(client);
    })();
  }, [name, env]);

  if (!client) {
    return null;
  }

  return client ? <ApolloProvider client={client}>{children}</ApolloProvider> : <></>;
};
