import React from 'react';
import { EnvironmentInfo } from '../../../api';
import MonitoringStats from './MonitoringStats';
import { TelemetryConnection } from './TelemetryConnection';

interface Props {
  name: string;
  env: EnvironmentInfo;
}

export const MonitoringStatsContainer: React.FC<Props> = ({ name, env }) => {
  return (
    <TelemetryConnection name={name} env={env.endpoint}>
      <MonitoringStats name={name} env={env} />
    </TelemetryConnection>
  );
};
