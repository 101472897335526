import { FunctionComponent } from 'react';
import { Title } from '@platformeco/ui-components';
import cn from 'classnames';
import { ReactComponent as Img404Dark } from './404_dark.svg';
import { ReactComponent as Img404Light } from './404_light.svg';
import styles from './NotFound.module.css';

const NotFound: FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <main className={cn(styles.container, className)}>
      <Img404Dark className={cn(styles.image, styles.dark)} />
      <Img404Light className={cn(styles.image, styles.light)} />
      <Title level={1}>Page Not Found :(</Title>
    </main>
  );
};

export default NotFound;
