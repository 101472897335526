import { FunctionComponent } from 'react';
import { MonitoringStatsError, MonitoringStatsLoading, Props } from './common';
import NewMonitoringStats from './new';
import OldMonitoringStats from './old';
import { useTelemetryStats } from './useTelemetryStats';

const MonitoringStats: FunctionComponent<Props> = ({ name, env }) => {
  const { data, loading, error } = useTelemetryStats(name, env);

  if (error) {
    console.error(`Error occurred during "${name}" monitoring stats loading`, error);
    return <MonitoringStatsError />;
  }

  if (loading || !data) {
    return <MonitoringStatsLoading />;
  }

  if (data && 'definitions' in data) {
    return <NewMonitoringStats {...data} />;
  }

  return <OldMonitoringStats {...data} />;
};

export default MonitoringStats;
