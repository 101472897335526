import { FunctionComponent, useState } from 'react';
import { PromiseFn, useAsync } from 'react-async';
import { Plate, IconError, Title } from '@platformeco/ui-components';
import cn from 'classnames';
import { getEnvs, EnvironmentInfo, ApplicationInfo } from 'api';
import styles from './ChannelCard.module.css';
import DeployStats, { DeployStatsLoading } from './DeployStats';
import LeftPart, { LeftPartLoading } from './LeftPart';
import { MonitoringStatsContainer } from './MonitoringStats';
import { MonitoringStatsLoading } from './MonitoringStats/MonitoringStats/common';
import RightPart, { RightPartLoading } from './RightPart';

const getChannelEnvs: PromiseFn<EnvironmentInfo[]> = async ({ name, controlCenterURL }) => {
  return await getEnvs(name, controlCenterURL);
};

const ListChannelCard: FunctionComponent<Props> = (props) => {
  const { app, className, vertical } = props;

  const [currentEnvName, setCurrentEnvName] = useState('');
  const { data: envs, error: envsError } = useAsync(getChannelEnvs, {
    name: app.title,
    controlCenterURL: app.controlCenterURL,
    repositoryURL: app.repositoryURL,
    onResolve: (envs) => !currentEnvName && setCurrentEnvName(envs.filter((e) => e.isProd)[0].id),
    onReject: (err) => console.error(`Error occured during loading "${app.title}" envs`, err),
  });

  const filteredEnvs = envs?.filter((e) => e.isProd);
  const currentEnv = filteredEnvs?.filter((e) => e.id === currentEnvName)[0];

  if (filteredEnvs && currentEnv) {
    return (
      <Plate className={cn(styles.card, className, { [styles.vertical]: vertical })}>
        <LeftPart
          envs={filteredEnvs.map((e) => e.id)}
          currentEnv={currentEnvName}
          onEnvChange={setCurrentEnvName}
          title={app.title}
          vertical={vertical}
        />
        <div className={styles.middle}>
          <DeployStats
            name={app.title}
            controlCenterURL={app.controlCenterURL}
            env={currentEnv}
            vertical={vertical}
          />
          <MonitoringStatsContainer name={app.title} env={currentEnv} />
        </div>
        <RightPart channel={app} vertical={vertical} />
      </Plate>
    );
  }

  if (envsError) {
    return <ChannelCardError {...props} />;
  }

  return <ChannelCardLoading {...props} />;
};

interface Props {
  app: ApplicationInfo;
  className?: string;
  vertical?: boolean;
}

function ChannelCardError({ className, vertical }: Props) {
  return (
    <Plate className={cn(styles.error, className, { [styles.vertical]: vertical })}>
      <IconError className={cn(styles.errorIcon)} size="XL" />
      <Title level={3}>Couldn't load channel info</Title>
    </Plate>
  );
}

export function ChannelCardLoading({
  className,
  vertical,
}: {
  className?: string;
  vertical?: boolean;
}) {
  return (
    <Plate className={cn(styles.card, className, { [styles.vertical]: vertical })}>
      <LeftPartLoading vertical={vertical} />
      <div className={styles.middle}>
        <DeployStatsLoading vertical={vertical} />
        <MonitoringStatsLoading />
      </div>
      <RightPartLoading vertical={vertical} />
    </Plate>
  );
}

export default ListChannelCard;
